import { Link } from "gatsby";
import React from "react";

import logo from '../../../images/logos/logo-color.svg';
import iconMenu from '../../../images/legacy/icon-menu.svg';
import iconClose from '../../../images/legacy/icon-close.svg';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleMobile = this.handleMobile.bind(this);

    this.state = {
      mobileMenuOpen: false
    };
  }

  handleMobile() {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen
    });
  }

  render() {
    return (
      <header className="header">
        <div className="header__content">
          <div className="header__logo">
            <Link to="/"><img src={logo} alt="Compute Software logo"/></Link>
          </div>
          <nav className="header__navigation-desktop">
            <ul>
              <li className="header__has-dropdown"><span className="header__menu">Platform</span>
                <div className="header__dropdown">
                  <Link className="header__dropdown-link" activeClassName="active" to="/platform">The Platform</Link>
                  <hr />
                  <span className="header__dropdown-section coming-soon">Analytics</span>
                  <span className="header__dropdown-section coming-soon">A/B Testing and Optimization Lab</span>
                  <span className="header__dropdown-section">Optimization Solution</span>
                  <Link className="header__dropdown-sublink" activeClassName="active" to="/amazonworkspaces">Amazon WorkSpaces</Link>
                </div>
              </li>
              <li><Link activeClassName="active" to="/documentation/overview">Documentation</Link></li>
              <li><Link activeClassName="active" to="/company">Company</Link></li>
              <li><Link className="button button--primary button--small" to="/request-demo?utm_source=amazon_workspaces&utm_medium=landing&utm_campaign=amazon_workspaces">Request a Demo</Link></li>
              <li><Link className="button button--outline button--small" to="/contact">Contact Us</Link></li>
              <li><a href="http://app.computesoftware.com/">Login</a></li>
            </ul>
          </nav>
          <button
            className="header__control-mobile"
            onClick={this.handleMobile}
          >
            {this.state.mobileMenuOpen ? <span className="screenreader-only">Close Menu</span> : <span className="screenreader-only">Open Menu</span>}
            <img alt="" src={this.state.mobileMenuOpen ? iconClose : iconMenu} aria-hidden="true" />
          </button>
        </div>
        <nav className={this.state.mobileMenuOpen ? "header__navigation-mobile header__navigation-mobile--open" : "header__navigation-mobile"}>
          <ul>
            <li><div className="header__dropdown">
                <Link className="header__dropdown-link" activeClassName="active" to="/platform">The Platform</Link>
                <Link className="header__dropdown-sublink" activeClassName="active" to="/amazonworkspaces">Amazon WorkSpaces</Link>
              </div>
            </li>
            <li><Link activeClassName="active" to="/documentation/overview">Documentation</Link></li>
            <li><Link activeClassName="active" to="/company">Company</Link></li>
            <li><Link activeClassName="active" to="/request-demo">Request a Demo</Link></li>
            <li><Link activeClassName="active" to="/contact">Contact Us</Link></li>
            <li><a href="http://app.computesoftware.com/">Login</a></li>
          </ul>
        </nav>
      </header>
    )
  }
};