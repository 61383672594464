import { Link } from "gatsby";
import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import logo from '../../../images/legacy/compute-software-logo-white.svg';

library.add(fab, faEnvelope);

const Footer = () => (
  <footer className="footer section">
    <div className="footer__primary">
      <div className="section__content">
        <div className="brand">
          <img className="brand__logo" src={logo} alt="Compute Software logo"/>
        </div>
        <div className="links">
          <ul>
            <li><Link to="/platform">Platform</Link></li>
            <li><Link to="/docs/platform-overview">Documentation</Link></li>
            <li><Link to="/company">Company</Link></li>
          </ul>
          <ul className="links__social">
            <li><a href="https://www.linkedin.com/company/computesoftware" target="blank"><span className="screenreader-only">LinkedIn</span><FontAwesomeIcon className="links__icon" icon={['fab', 'linkedin']} /></a></li>
            <li><a href="https://twitter.com/ComputeInc" target="blank"><span className="screenreader-only">Twitter</span><FontAwesomeIcon className="links__icon" icon={['fab', 'twitter']} /></a></li>
            <li><a href="mailto:contact@computesoftware.com"><span className="screenreader-only">Email</span><FontAwesomeIcon className="links__icon" icon="envelope" /></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="footer__secondary">
      <div className="section__content">
        <ul>
          <li className="copyright">&copy; {(new Date().getFullYear())} Compute Software, Inc.</li>
          <li><Link to="/privacy">Privacy</Link></li>
          <li><Link to="/website-terms">Website Terms</Link></li>
          <li><Link to="/saas-terms">SaaS Terms</Link></li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;