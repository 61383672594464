import React from "react";
import { Link } from "gatsby";

import SEO from "../components/seo";
import Page from "../components/legacy/page";
import ContactForm from "../components/legacy/sections/contact-form";

import iconColumn1 from '../images/legacy/amazonworkspaces/section1_icon1.svg';
import iconColumn2 from '../images/legacy/amazonworkspaces/section1_icon2.svg';
import iconColumn3 from '../images/legacy/amazonworkspaces/section1_icon3.svg';

import imageSection2 from '../images/legacy/amazonworkspaces/section2_image.png';
import imageSection3 from '../images/legacy/amazonworkspaces/section3_image.png';
import imageSection4 from '../images/legacy/amazonworkspaces/section4_image.svg';

const content = require('../content/amazonworkspaces/amazonworkspaces.json');

const PlatformPage = () => (
  <Page id="amazonworkspaces">
    <SEO title="Amazon WorkSpaces Optimization | Cloud Optimization and Cloud Cost Management | Compute Software" />
    <div className="section hero">
      <div className="section__content">
        <div className="hero__content">
          <span className="hero__pageIntro">{content.hero.intro}</span>
          <h1 className="hero__header">{content.hero.header}</h1>
          <span className="hero__subheader text--large">{content.hero.subheader}</span>
          <Link className="button button--large" to={content.hero.ctaUrl}>{content.hero.cta}</Link>
        </div>
      </div>
      <div className="background-overlay"/>
    </div>
    <div className="section section--secondary section1__columns">
      <div className="section__content">
        <h2>{content.section1.header}</h2>
        <div className="columns-3 text--large">
          <div>
            <div className="illustration">
              <img src={iconColumn1} alt="" aria-hidden={true} />
            </div>
            <div>
              <h3>{content.section1.column1.header}</h3>
              <p>{content.section1.column1.description}</p>
            </div>
          </div>
          <div>
            <div className="illustration">
              <img src={iconColumn2} alt="" aria-hidden={true} />
            </div>
            <div>
              <h3>{content.section1.column2.header}</h3>
              <p>{content.section1.column2.description}</p>
            </div>
          </div>
          <div>
            <div className="illustration">
              <img src={iconColumn3} alt="" aria-hidden={true} />
            </div>
            <div>
              <h3>{content.section1.column3.header}</h3>
              <p>{content.section1.column3.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section section--alternate section__benefits">
      <div className="section__content">
        <div className="columns-2">
          <div>
            <h2>{content.section2.header}</h2>
            <ul>
              {content.section2.bullets.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          </div>
          <div>
            <img className="image" src={imageSection2} alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="section section__benefits">
      <div className="section__content">
        <div className="columns-2 reverse-row">
          <div>
            <img className="image" src={imageSection3} alt="" />
          </div>
          <div>
            <h2>{content.section3.header}</h2>
            <ul>
              {content.section3.bullets.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="section section--alternate section__benefits">
      <div className="section__content">
        <div className="columns-2">
          <div>
            <h2>{content.section4.header}</h2>
            <ul>
              {content.section4.bullets.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          </div>
          <div>
            <img src={imageSection4} alt="" />
          </div>
        </div>
      </div>
    </div>
    <ContactForm header={["Is your company looking to drive down the costs of Amazon WorkSpaces?", <br/>,
"Contact our team to learn more"]} page="amazonworkspaces" />
  </Page>
);

export default PlatformPage;