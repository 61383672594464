import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./sections/header";
import Footer from "./sections/footer";
import "../../styles/legacy/amazonworkspaces.scss";

const Page = (props, location) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="amazonworkspaces-wrapper">
      <div id={props.id}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="content">
          {props.children}
          <Footer />
        </main>
      </div>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string
};

export default Page;