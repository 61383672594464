import React from "react";

const ContactForm = (props) => (
  <div className="section section--contact">
    <div className="section__content">
      <h2>{props.header ? props.header : "See Compute Software in Action"}</h2>
      <form name="demo" method="POST" action="/success" data-netlify="true" className="width-condensed form--reverse" data-netlify-honeypot="bot-field">
        <div className="formField hidden">
          <label className="formLabel" htmlFor="source">Source</label>
          <input id="source" name="source" type="text" className="formInput" value="Amazon Workspaces"/>
        </div>
        <div className="formField">
          <label className="formLabel" htmlFor="firstName">First Name</label>
          <input id="firstName" name="firstName" className="formInput" required/>
        </div>
        <div className="formField">
          <label className="formLabel" htmlFor="lastName">Last Name</label>
          <input id="lastName" name="lastName" className="formInput" required/>
        </div>
        <div className="formField">
          <label className="formLabel" htmlFor="email">Email Address</label>
          <input id="email" name="email" className="formInput" required/>
        </div>
        <div className="formField">
          <label className="formLabel" htmlFor="company">Company Name</label>
          <input id="company" name="company" className="formInput" required/>
        </div>
        <div className="formField">
          <label className="formLabel" htmlFor="title">Title</label>
          <input id="title" name="title" className="formInput" required/>
        </div>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="page" value={props.page}  />
        <input type="hidden" name="form-name" value="demo"  />
        <div className="formSubmit">
          <button type="submit" className="button button--reverse">Schedule My Demo</button>
        </div>
      </form>
    </div>
  </div>
);

export default ContactForm;